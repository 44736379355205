import { render, staticRenderFns } from "./manage.vue?vue&type=template&id=32b3eb60&scoped=true&"
import script from "./manage.vue?vue&type=script&lang=js&"
export * from "./manage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32b3eb60",
  null
  
)

export default component.exports