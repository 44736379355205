<template>
    <div>
        <el-tabs style="height: 200px;">
            <el-tab-pane label="消息发布">
                <message-release></message-release>
            </el-tab-pane>
            <el-tab-pane label="消息列表">
                <message-list></message-list>
            </el-tab-pane>

<!--            <el-button type="primary" @click="visible = false">确定</el-button>-->
<!--            <el-tab-pane label="发布新消息">-->
<!--            </el-tab-pane>-->
        </el-tabs>
    </div>
</template>

<script>
    import MessageRelease from './release'
    import MessageList from './list'
    export default {
        name: "message-manage",
        components:{
            MessageRelease,
            MessageList
        }
    }
</script>

<style scoped>

</style>
